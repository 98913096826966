import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
//import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const CyberSecTag2023 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            activityForum23PDF: file(relativePath: { eq: "Publications/Activities/2023-forum23.pdf" }) {
                name
                publicURL
            }

        }
    `)

    return (
        <div className={state.eventsId}>
            <div className="box">
                <div className="paper-container">
                <div className="calendar">
                        <div className="outside">
                            <div className="inside">         
                                <p className="datum">29.06.2023</p>
                            </div>
                        </div>
                    </div>
                    <div className="outside">
                        <div className="inside">                                   
                            <p>33. Cyber-Sicherheits-Tag</p>
                            
                            <p>
                                {/* <a  href="https://www.allianz-fuer-cybersicherheit.de/Webs/ACS/DE/Netzwerk-Formate/Veranstaltungen-und-Austausch/Cyber-Sicherheits-Tage/20230629/33CST_node.html" 
                                    target="_blank" 
                                    rel="noreferrer">
                                    Website & Programm
                                </a> */}
                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CyberSecTag2023;