import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const REPORT02 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            scienceReport02: file(relativePath: { eq: "Publications/Scientific/studie-2-master-final.pdf" }) {
                name
                publicURL
            }
            scienceReport02Image: file(relativePath: { eq: "Publications/Scientific/report-02.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="flyer-container">
                    <div className="image-container">
                    <a href={data.scienceReport02.publicURL} download>
                    <Img fluid={data.scienceReport02Image.childImageSharp.fluid} className="image"/> 
                    </a>
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Enabling vs. Entmündigung <br />(2023)</p>
                            <p className="subtitle"><a href={data.scienceReport02.publicURL} >Download Studie 2 (8 MB)</a></p>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
};

export default REPORT02;