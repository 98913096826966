// he Current State of Information Security Awareness in German SMEs
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const IJETAE2021 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            scientificIjetae: file(relativePath: { eq: "Publications/Scientific/ijetae.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                        <Img fluid={data.scientificIjetae.childImageSharp.fluid} className="image"/>
                    </div>
                    <div className="outside">
                        <div className="inside">     
                            <p>The Current State of Information Security Awareness in German SMEs</p>
                            <p className="subtitle" >
                                <a href="https://ijetae.com"  
                                    target="_blank" 
                                    rel="noreferrer" 
                                    aria-label="The Current State of Information Security Awareness in German SMEs">
                                    Link zur Website
                                </a>
                                </p>
                                <p className="subtitle">
                                <a href="https://ijetae.com/files/Volume11Issue12/IJETAE_1221_16.pdf"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="Link zum Paper '(How) Can Directive (EU) 2019/1937 on whistleblowers be used #to build up a securityand safety culture in Institutions?'">
                                    Direktlink zum Text
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default IJETAE2021;