import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const TH202006 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            pressAlarmKickOffTH: file(relativePath: { eq: "Publications/Press/Presse-ALARM-KickOff-TH.pdf" }) {
                name
                publicURL
            }
            pressAlarmKickOff: file(relativePath: { eq: "Publications/Press/lockedKeyboard.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.pressId}>
            <div className="box">
                <div className="image-container">
                <p className="subtitle" >
                    <a href={data.pressAlarmKickOffTH.publicURL} target="_blank" rel="noreferrer">
                        <Img fluid={data.pressAlarmKickOff.childImageSharp.fluid} className="image"/> 
                    </a>
                    </p>
                </div>
                <p>ALARM Kick-Off</p>
                <p className="subtitle">Pressemitteilung der TH Wildau</p>
            </div>
        </div>
    )
};

export default TH202006;