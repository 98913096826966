import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const ABSCHLUSS2024 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            abschluss2024: file(relativePath: { eq: "Publications/Scientific/abschlussbericht.pdf" }) {
                name
                publicURL
            }
            abschluss2024Image: file(relativePath: { eq: "Publications/Scientific/abschlussbericht.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="flyer-container">
                    <div className="image-container">
                    <a href={data.abschluss2024.publicURL} download>
                    <Img fluid={data.abschluss2024Image.childImageSharp.fluid} className="image"/> 
                    </a>
                    </div>
                        <p className="subtitle" >
                          <a href="https://buchwelten-verlag.de/ebooks.php" target="_blank" rel="noreferrer">
                            Projektdokumentation als Buch<br/> 
                          </a>
                          (2024)
                          </p>
                </div>
            </div>
        </div>
    )
};

export default ABSCHLUSS2024;