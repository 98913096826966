import React, {useContext} from 'react'
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const DABDigitalforum2023 = (state) => {
    state = useContext(GlobalStateContext);

    return (
        <div className={state.eventsId}>
            <div className="box">
                <div className="paper-container">
                <div className="calendar">
                        <div className="outside">
                            <div className="inside">         
                                <p className="datum">11.10.2023</p>
                            </div>
                        </div>
                    </div>
                    <div className="outside">
                        <div className="inside">                                   
                            <p>DAB Digitalforum 2023<br/> Berlin</p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DABDigitalforum2023;