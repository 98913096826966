import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const StudieDrei2023en = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            studieDreiPdf: file(relativePath: { eq: "Publications/Scientific/ALARM-Study-3_Chapter-5_Scholl_final-en.pdf" }) {
                name
                publicURL
            }
            studieDrei2023Image: file(relativePath: { eq: "Publications/Scientific/studie3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="flyer-container">
                    <div className="image-container">
                    <a href={data.studieDreiPdf.publicURL} download>
                    <Img fluid={data.studieDrei2023Image.childImageSharp.fluid} className="image"/> 
                    </a>
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Game over vs. Game lover</p>
                            <p className="subtitle"><a href={data.studieDreiPdf.publicURL} >Download Chapter 5</a></p>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
};

export default StudieDrei2023en;