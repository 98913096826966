// Qualitative Wirkungsanalyse Security Awareness in KMU Tiefenpsychologische Grundlagenstudie
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const RainerRG = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            scientificResearchGate: file(relativePath: { eq: "Publications/Scientific/research-gate.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
        <div className="box">
            <div className="paper-container">
                <div className="image-container">
                    <Img fluid={data.scientificResearchGate.childImageSharp.fluid} className="image"/>
                </div>
                <p>
                Cyberattacks:<br/>  An Attempt to Obtain<br/> a Multidimensional <br/>
                Awareness Indicator
                </p>
                <p className="subtitle" >2023</p>
                <p className="subtitle">
                <a
                    href="https://www.researchgate.net/publication/376185998_Cyberattacks_An_Attempt_to_Obtain_a_Multidimensional_Awareness_Indicator"  
                    target="_blank" 
                    rel="noreferrer" 
                    aria-label="DOI:10.13140/RG.2.2.29494.88642">
                    DOI:<br/>10.13140/RG.2.2.29494.88642
                    </a>  
                </p>
            </div>
        </div>
    </div>
    )
};

export default RainerRG;