//  Qualitative Wirkungsanalyse Security Awareness in KMU
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const QWSAKMiU = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            awarenessKMU: file(relativePath: { eq: "Publications/Scientific/alarm-informationssicherheit-studie-1.pdf" }) {
                name
                publicURL
            }
            awarenessKMUshort: file(relativePath: { eq: "Publications/Scientific/kurzfassung-alarm-informationssicherheit-studie-1.pdf" }) {
                name
                publicURL
            }
            awarenessKMUImage: file(relativePath: { eq: "Publications/Scientific/awareness-in-kmu.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                    <a href={data.awarenessKMU.publicURL} download>
                    <Img fluid={data.awarenessKMUImage.childImageSharp.fluid} className="image"/> 
                    </a>
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Qualitative Wirkungsanalyse <br />Security Awareness in KMU</p>
                        <p className="subtitle">
                            <a href={data.awarenessKMU.publicURL}>Download (7 MB)</a><br/>
                            <a href={data.awarenessKMUshort.publicURL}>Download Kurzversion</a></p>
                        </div>
                    </div>     
                </div>
            </div>
        </div>
    )
};

export default QWSAKMiU;