import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
//import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const Forum2023 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            activityForum23PDF: file(relativePath: { eq: "Publications/Activities/2023-forum23.pdf" }) {
                name
                publicURL
            }

        }
    `)

    return (
        <div className={state.eventsId}>
              <div className="box">
                            <div className="paper-container">
                            <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">23.06.2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="outside">
                                    <div className="inside">                                   
                                        <p>Awareness Forum 2023</p>
                                        <p>(Abschlussveranstaltung)</p>
                                        <p>
                                            <a href={data.activityForum23PDF.publicURL} 
                                            target="_blank" rel="noreferrer">Einladung & Programm</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
    )
};

export default Forum2023;