import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const IvmSGsudIdMe17 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            jtHwtHwr: file(relativePath: { eq: "Publications/Scientific/.pdf" }) {
                name
                publicURL
            }
            jtHwtHwrImage: file(relativePath: { eq: "Publications/Scientific/jt-hwr-hwt.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                    <Img fluid={data.jtHwtHwrImage.childImageSharp.fluid} className="image"/>                
                    </div>
                    <div className="outside">
                        <div className="inside">     
                            <p>Informationssicherheitskultur verstehen, mit Serious Games 
                                sensibilisieren und das Informationssicherheits- bewusstsein der Mitarbeitenden erhöhen</p>
                            <p className="subtitle" >
                                <a href="https://opus4.kobv.de/opus4-th-wildau/files/1794/Tagungsband_AKWI_2023.pdf"  
                                    target="_blank" 
                                    rel="noreferrer" 
                                    aria-label="">
                                    Link zum PDF (12 MB))
                                </a>
                            </p>
                            <p className="subtitle" >
                                <a href="https://opus4.kobv.de/opus4-th-wildau/frontdoor/index/index/docId/1794"  
                                    target="_blank" 
                                    rel="noreferrer" 
                                    aria-label="">
                                    Website zum Tagungsband
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default IvmSGsudIdMe17;