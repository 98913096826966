import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const REPORT01 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            scienceReport01: file(relativePath: { eq: "Publications/Scientific/alarm-informationssicherheit-report-1.pdf" }) {
                name
                publicURL
            }
            scienceReport01Image: file(relativePath: { eq: "Publications/Scientific/report-01.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="flyer-container">
                    <div className="image-container">
                        <a href={data.scienceReport01.publicURL} download>
                        <Img fluid={data.scienceReport01Image.childImageSharp.fluid} className="image"/> 
                        </a>
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Sicherheitsrelevante Tätigkeitsprofile in KMU (2021)</p>
                            <p className="subtitle"><a href={data.scienceReport01.publicURL}>Download Studie 1 (8 MB)</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default REPORT01;