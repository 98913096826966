//  Qualitative Wirkungsanalyse Security Awareness in KMU
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const LiceWcsne2022 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            liceWcsnePDF: file(relativePath: { eq: "Publications/Scientific/2022-lice-wcsne-proceedings.pdf" }) {
                name
                publicURL
            }
            liceWcsneImage: file(relativePath: { eq: "Publications/Scientific/lice-wcsne.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)


    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                    <Img fluid={data.liceWcsneImage.childImageSharp.fluid} className="image"/> 
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Identification of Target Groups in German SMEs Based on Job Profiles</p> 
                        <p className="subtitle">
                            <a href={data.liceWcsnePDF.publicURL}>Download Proceedings (1 MB)</a>
                        </p>
                        <p>
                        <a href="https://www.researchgate.net/profile/Margit-Scholl/publication/368951062_Tailored_Information_Security_Training_Identification_of_Target_Groups_in_German_SMEs_Based_on_Job_Profiles/links/640798f10d98a97717e82f09/Tailored-Information-Security-Training-Identification-of-Target-Groups-in-German-SMEs-Based-on-Job-Profiles.pdf"  
                                target="_blank" 
                                rel="noreferrer" 
                                aria-label="Identification of Target Groups in German SMEs Based on Job Profiles.">
                                Link: Website
                            </a> 
                        </p>
                       </div>
                    </div>     
                </div>
            </div>
        </div>
    )
};
export default LiceWcsne2022;