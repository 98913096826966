//  Qualitative Wirkungsanalyse Security Awareness in KMU
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const Hicss2023Praesi = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            hicss2023Presentation: file(relativePath: { eq: "Publications/Scientific/2023-hicss-scholl-m-presaentation.pdf" }) {
                name
                publicURL
            }
            hicss2023Image: file(relativePath: { eq: "Publications/Scientific/2023hicss.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)


    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                    <Img fluid={data.hicss2023Image.childImageSharp.fluid} className="image"/> 
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Sustainable Information Security Sensitization in SMEs: 
                                <br />Designing Measures 
                                <br />with Long-Term Effect</p> 
                        <p className="subtitle">
                            <a href={data.hicss2023Presentation.publicURL}>Download Präsentation <br/>with Comments (5 MB)</a>
                        </p>
                        <p className="subtitle">
                            <a href="https://www.researchgate.net/publication/366904572_2023_HICSS-56_presentation_SchollM_final_publicationcomments"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="DOI: 10.13140/RG.2.2.34339.53286">
                                    DOI: 10.13140/RG.2.2.34339.53286
                                </a>
                        </p>
                       </div>
                    </div>     
                </div>
            </div>
        </div>
    )
};
export default Hicss2023Praesi;