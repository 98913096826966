// Qualitative Wirkungsanalyse Security Awareness in KMU Tiefenpsychologische Grundlagenstudie
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const QWSAiKMUTG = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            scientificResearchGate: file(relativePath: { eq: "Publications/Scientific/research-gate.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
                        <div className="box">
                            <div className="paper-container">
                                <div className="image-container">
                                    <Img fluid={data.scientificResearchGate.childImageSharp.fluid} className="image"/>
                                </div>
                                <div className="outside">
                                    <div className="inside">  
                                        <p> Vorwort zur: "Qualitative Wirkungsanalyse Security Awareness in KMU 
                                            Tiefenpsychologische Grundlagenstudie"<br/>
                                        </p>
                                        <p className="subtitle"> (auf Englisch)</p>
                                        <p className="subtitle" >2021</p>
                                        <p className="subtitle">
                                        <a href="https://doi.org/10.13140/RG.2.2.21236.88961"  
                                            target="_blank" 
                                            rel="noreferrer" 
                                            aria-label="Qualitative Wirkungsanalyse Security Awareness in KMU -- Tiefenpsychologische Grundlagenstudie">
                                            Link: Website
                                            </a>  
                                        </p>
                                    </div>
                                </div>       
                            </div>
                        </div>
                    </div>
    )
};

export default QWSAiKMUTG;