import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const SCHLUSS2024 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            schluss2024: file(relativePath: { eq: "Publications/Scientific/alarm-schlussbericht-2024.pdf" }) {
                name
                publicURL
            }
            schluss2024Image: file(relativePath: { eq: "Publications/Scientific/schlussbericht2024.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="flyer-container">
                    <div className="image-container">
                    <a href={data.schluss2024.publicURL} download>
                    <Img fluid={data.schluss2024Image.childImageSharp.fluid} className="image"/> 
                    </a>
                    </div>
                        <p><a href={data.schluss2024.publicURL} >Schlussbericht </a></p>
                        <p>(Download PDF 9 MB)</p>
                </div>
            </div>
        </div>
    )
};

export default SCHLUSS2024;