// Global Cybersecurity Index (GCI) and the Role of its 5 Pillars (Englisch)
import React, {useContext} from 'react'
//import {useStaticQuery, graphql} from "gatsby"
//import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"
import ScientificSpringer from "./springerlink.inline.svg";

const SPRINGER2021 = (state) => {
    state = useContext(GlobalStateContext);

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                        <ScientificSpringer className="image svg"/>
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Global Cybersecurity Index (GCI) and the Role of its 5 Pillars (Englisch)</p>
                            <p className="subtitle" >
                                <a href="https://link.springer.com/article/10.1007/s11205-021-02739-y"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="Link zum text des Papers 'Global Cybersecurity Index (GCI) and the Role of its 5 Pillars'">
                                    Link zum Text
                                </a>
                            </p>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
};

export default SPRINGER2021;