import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const Podcast2024B = (state) => {
    state = useContext(GlobalStateContext);
    const data = useStaticQuery(graphql`
        query {
            podcastPDFB: file(relativePath: { eq: "Publications/Press/Idea-02_ms-final.pdf" }) {
                name
                publicURL
            }
            podcastImageB: file(relativePath: { eq: "Publications/Press/researchpod-b.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.pressId}>
        <div className="box">
            <div className="image-container">
                <p className="subtitle" >
                    <a href="https://researchpod.org/informatics-technology/alarm-information-security-training"
                       target="_blank" rel="noreferrer">
                        <Img fluid={data.podcastImageB.childImageSharp.fluid} className="image"/> 
                    </a>
                </p>
            </div>
                <p className="subtitle" >
                    <a href={data.podcastPDFB.publicURL} target="_blank" rel="noreferrer">
                        Transcript
                    </a>
                </p>
                <p className="subtitle">
                <a href="https://researchpod.org/informatics-technology/alarm-information-security-training"
                    target="_blank" rel="noreferrer" 
                    aria-label="Podcast 2">
                     Résumé of the Gamified Increase in Security Awareness
                 </a>
            </p>
        </div>
    </div>
        
    )
};

export default Podcast2024B;