import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const ReginaInterview2022 = (state) => {
    state = useContext(GlobalStateContext);
    const data = useStaticQuery(graphql`
        query {
            pressReginaInterview: file(relativePath: { eq: "Publications/Press/spielendLernenForum.png" }) {
                childImageSharp {
                    fluid(maxHeight: 500, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.pressId}>
            <div className="box">
                <div className="image-container">
                    <a  href="https://bc-production.pressmatrix.com/de/profiles/6b0f1aad0817/editions/b323fb2054c8e2ca99fb/pages/page/24" 
                        target="_blank" rel="noreferrer">
                    <Img fluid={data.pressReginaInterview.childImageSharp.fluid} className="image"/> 
                    </a>
                </div>
                <p>
                Unkonventionelle Schulungen
                </p>
                <p className="subtitle" >
                    <a href="https://bc-production.pressmatrix.com/de/profiles/6b0f1aad0817/editions/b323fb2054c8e2ca99fb/pages/page/24"
                        target="_blank" rel="noreferrer" 
                        aria-label="Blogartikel">
                        Link zu Website
                    </a>
                </p>
            </div>
        </div>
    )
};

export default ReginaInterview2022;