import React, {useContext} from 'react'
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const AwarenessStuttgart2024 = (state) => {
    state = useContext(GlobalStateContext);
    return (
        <div className={state.eventsId}>
            <div className="box">
                <div className="paper-container">
                <div className="calendar">
                        <div className="outside">
                            <div className="inside">         
                                <p className="datum">22.03.2024</p>
                            </div>
                        </div>
                    </div>
                    <div className="outside">
                        <div className="inside">                                   
                            <p>Cyber-Angreifende hacken Mitarbeitende und Führungskräfte - was sollten KMU tun?<br />(IHK Stuttgart)</p>
                            <p> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AwarenessStuttgart2024;