import React, {useContext} from 'react'
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const HWKPotsdam2023 = (state) => {
    state = useContext(GlobalStateContext);

    return (
        <div className={state.eventsId}>
            <div className="box">
                <div className="paper-container">
                <div className="calendar">
                        <div className="outside">
                            <div className="inside">         
                                <p className="datum">07.12.2023</p>
                            </div>
                        </div>
                    </div>
                    <div className="outside">
                        <div className="inside">                                   
                            <p>HWK-interne Sensibilisierung, <br/>Handwerkskammer Potsdam</p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HWKPotsdam2023;