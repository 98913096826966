import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const Lissabon2022 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            lissabon2022Image: file(relativePath: { eq: "Publications/Scientific/lissabon-2022.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            } 
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                    <Img fluid={data.lissabon2022Image.childImageSharp.fluid} className="image"/>                    
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Using Emotional Design <br />to Raise Awareness <br/>of Information Security</p>
                            <br />
                            <p className="subtitle">
                                <a href="http://www.iadisportal.org/digital-library/using-emotional-design-to-raise-awareness-of-information-security"  
                                    target="_blank" 
                                    rel="noreferrer" 
                                    aria-label="">
                                Link zum Artikel
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Lissabon2022;