// he Current State of Information Security Awareness in German SMEs
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"


const DLINE021 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            scientificDline: file(relativePath: { eq: "Publications/Scientific/DlineJournalLogo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                        <Img fluid={data.scientificDline.childImageSharp.fluid} className="image"/>
                    </div>
                    <div className="outside">
                        <div className="inside">     
                            <p>(How) Can Directive (EU) 2019/1937 on whistle-<br/>blowers be used to build  <br/>up a security and safety culture in Institutions </p>
                            <p className="subtitle">(Englisch)</p>
                            <p className="subtitle">
                                <a href="https://www.dline.info/isej/v7n2.php"  
                                target="_blank" rel="noreferrer" 
                                aria-label="Link zum Journel in dem das Paper '(How) Can Directive (EU) 2019/1937 on whistleblowers be used to build up a securityand safety culture in Institutions?'  veröffentlicht wurde">
                                    Link zum Journal
                                </a>
                                </p>
                            <p className="subtitle">
                                <a href="https://www.dline.info/isej/fulltext/v7n2/isejv7n2_2.pdf"  
                                target="_blank" rel="noreferrer" 
                                aria-label="Link zum Paper '(How) Can Directive (EU) 2019/1937 on whistleblowers be used to build up a securityand safety culture in Institutions?'">
                                    Direktlink zum Text
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DLINE021;