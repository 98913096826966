import React, {useContext} from 'react'
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const AwarenessPotsdam2024 = (state) => {
    state = useContext(GlobalStateContext);
    return (
        <div className={state.eventsId}>
            <div className="box">
                <div className="paper-container">
                <div className="calendar">
                        <div className="outside">
                            <div className="inside">         
                                <p className="datum">31.01.2024</p>
                            </div>
                        </div>
                    </div>
                    <div className="outside">
                        <div className="inside">                                   
                            <p>Spielend IT-Sicherheit beachten in Caputh<br/> (Handwerkskammer Potsdam)</p>
                            
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AwarenessPotsdam2024;