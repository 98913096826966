// Global Cybersecurity Index (GCI) and the Role of its 5 Pillars (Englisch)
import React, {useContext} from 'react'
//import {useStaticQuery, graphql} from "gatsby"
//import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"
import ScientificSpringer from "./springerlink.inline.svg";

const SPRINGER2023MS = (state) => {
    state = useContext(GlobalStateContext);

    return (
        <div className={state.publicationsId}>
            <div className="box">
                <div className="paper-container">
                    <div className="image-container">
                        <ScientificSpringer className="image svg"/>
                    </div>
                    <div className="outside">
                        <div className="inside">  
                            <p>Raising Awareness of CEO Fraud in Germany: 
                                Emotionally Engaging Narratives Are a MUST for Long-Term Efficacy
                                </p>
                            <p className="subtitle" >
                                <a href="https://link.springer.com/chapter/10.1007/978-3-031-33258-6_40"  
                                    target="_blank" rel="noreferrer" 
                                    aria-label="Link zum Text des Papers 'Raising Awareness of CEO Fraud in Germany'">
                                    Link zum Text
                                </a>
                            </p>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
};

export default SPRINGER2023MS;