// Das erste Plakat des ALARM Projekts
import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"


const FiwiP2 = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            fiwi2PDF2: file(relativePath: { eq: "Publications/Flyer/220905-AKWI-poster.pdf" }) {
                name
                publicURL
            }
            fiwi2Image2: file(relativePath: { eq: "Publications/Flyer/fachtag-informatik-wildau2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <div className={state.flyerId}>
            <div className="box">
                <div className="image-container">
                    <a href={data.fiwi2PDF2.publicURL} download>
                        <Img fluid={data.fiwi2Image2.childImageSharp.fluid} className="image"/> 
                    </a>
                </div>
                <br />
                <p>2. Plakat für den Fachtag Informatik an der TH Wildau</p>
            </div>
        </div> 
    )
};

export default FiwiP2;