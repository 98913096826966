import React, {useContext} from 'react'
import {useStaticQuery, graphql} from "gatsby"
//import Img from "gatsby-image"
import {GlobalStateContext} from "../../../context/GlobalContextProvider"

const Impressionen = (state) => {
    state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            activityImpressionen: file(relativePath: { eq: "Publications/Activities/impressionen.zip" }) {
                name
                publicURL
            }

        }
    `)

    return (
        <div className={state.eventsId}>
              <div className="box">
                            <div className="paper-container">
                            <div className="calendar">
                                    <div className="outside">
                                        <div className="inside">         
                                            <p className="datum">31.03.2024</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="outside">
                                    <div className="inside">                                   
                                        <p>Bild-Impressionen</p>
                                        <p>2020-2024</p>
                                        <p>
                                            <a href={data.activityImpressionen.publicURL} 
                                            target="_blank" rel="noreferrer">Download (zip) 1,35 MB</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
    )
};

export default Impressionen;